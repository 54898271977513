<template>
  <div class="editor-lists">
    <h3>Redaktionelle Listen</h3>
    <b-card title="Neue Liste hinzufügen" class="mt-3">
      <b-form-group>
        <b-form-group label="Listen - Id">
          <b-form-input v-model="formData.id" disabled></b-form-input>
        </b-form-group>
        <b-form-group label="Listen - Name">
          <b-form-input v-model="formData.name"></b-form-input>
        </b-form-group>
        <b-form-group label="Listen - Sortierung">
          <b-form-input v-model="formData.listOrder"></b-form-input>
        </b-form-group>
        <b-form-group label="Medien - Auswahl">
          <v-select
            v-model="formData.items"
            :options="mediaOptions"
            @search="onSearch"
            multiple
          ><li slot="list-footer" class="pagination">
      <button v-if="lookForMore" @click="searchMore()">More</button></li></v-select>
        </b-form-group>
        <b-button class="float-right" @click="storeList">Speichern</b-button>
      </b-form-group>
    </b-card>
    <b-card title="Listen" class="mt-3">
      <div v-for="list in lists" :key="list.id">
        <b-card :title="cardTitle(list)" class="mt-3">
          <b>Sortierungswert: {{ list.listOrder }}</b>

          <div v-for="item in list.items" :key="item.subvodioId">
            <div>
              {{ item.name }}
            </div>
          </div>
          <img
            src="images/edit.svg"
            alt="Edit"
            @click="setEditData(list)"
            class="mr-3"
          />

          <img
            src="images/delete.svg"
            alt="Löschen"
            @click="removeList(list)"
          />
        </b-card>
      </div>
    </b-card>
  </div>
</template>

<script>
// @ is an alias to /src
import SUBVODIO from "@/utils/AdminApi";
import { v4 as uuidv4 } from "uuid";
import settings from "@/utils/Settings";

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export default {
  name: "EditorLists",
  components: {},
  data() {
    return {
      user: SUBVODIO.user(),
      media: [],
      mediaOptions: [],
      formData: {
        id: uuidv4(),
        name: "",
        items: [],
      },
      lists: [],
      lookForMore: false,
      page: 1,
      pageSize: 10,
      search: "",
      actionResult: {
        error: false,
        message: "",
      },
    };
  },

  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "Job nicht gespeichert"
        : "Job erfolgreich gespeichert";
    },
  },

  mounted() {
    //this.getMedia();
    this.getLists();
  },

  methods: {
    searchIt: debounce((search, loading, vm) => {
      if (vm == undefined) {
        vm = this;
      }
      SUBVODIO.getMediaByTitle(search, vm.page, vm.pageSize)
        .then((response) => {
          const result = response.data.length > 0 ? response.data : [];
          
          vm.options = result;
          vm.mediaOptions = result.map(i => vm.setMediaOptions(i));
          
          loading(false);
          if (result.length == vm.pageSize) {
            vm.page = 1;
            vm.lookForMore = true;
          }
          else {
            vm.lookForMore = false;
          }
        })
        .catch((err) => console.log(err));
    }, 400),
    searchMore() {
      if (this.lookForMore) {
        this.page += 1;
        SUBVODIO.getMediaByTitle(this.search, this.page, this.pageSize)
        .then((response) => {
            const result = response.data.length > 0 ? response.data : [];
          
          this.options = this.options.concat(result);
          this.mediaOptions = this.mediaOptions.concat(result.map(i => this.setMediaOptions(i)));
          
          if (result.length == this.pageSize) {
            this.page += 1;
            this.lookForMore = true;
          }
          else {
            this.lookForMore = false;
          }
        })
        .catch((err) => console.log(err));
      }
    },
    onSearch(search, loading) {
      loading(true);
      this.search = search;
      this.page = 1;
      this.media = [];
      this.mediaOptions = [];
      this.searchIt(search, loading, this);
    },
    cardTitle(list) {
      return list.name;
    },
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },

    setSuccessMessage(type) {
      this.actionResult = {
        error: false,
        message:
          type +
          " am " +
          new Date().toLocaleString(undefined, settings.dateFormat) +
          " geladen.",
      };
      this.makeToast();
    },
    setErrorMessage(error) {
      this.actionResult = {
        error: true,
        message: "Es gab einen Fehler bei der Bearbeitung: " + error.message,
      };
      this.makeToast();
    },

    setEditData(list) {
      const itemIds = list.items.map((item) => this.setMediaOptions(item));
      this.formData.id = list.listId;
      this.formData.name = list.name;
      this.formData.listOrder = list.listOrder;
      this.formData.items = itemIds;
    },

    setMediaOptions(item) {
      return {
        code: item.subvodioId,
        label: item.name,
      };
    },

    getMedia() {
      SUBVODIO.getAllMedia().then((response) => {
        this.media = response.data.map((item) => JSON.parse(item));
        this.mediaOptions = this.media.map((item) =>
          this.setMediaOptions(item)
        );
      });
    },

    transformFormData() {
      const items = this.formData.items.map((item) => item.code);
      return {
        id: this.formData.id,
        name: this.formData.name,
        listOrder: this.formData.listOrder,
        subvodioIds: items,
      };
    },
    storeList() {
      const listData = this.transformFormData();
      SUBVODIO.persistEditorList(listData)
        .then((response) => {
          this.setSuccessMessage("Liste");
          this.getLists();
          this.formData = {
            id: uuidv4(),
            name: "",
            items: [],
            listOrder: null,
          };
        })
        .catch((error) => {
          this.setErrorMessage(error);
        });
    },

    getLists() {
      SUBVODIO.getEditorLists().then((response) => {
        this.lists = response.data;
      });
    },
    removeListFromLists(listId) {
      const indexToDelete = this.lists.findIndex(
        (list) => list.listId === listId
      );
      this.lists.splice(indexToDelete, 1);
    },
    removeList(list) {
      SUBVODIO.removeList(list.listId)
        .then(() => {
          this.removeListFromLists(list.listId);
          this.setSuccessMessage("Liste");
        })
        .catch((error) => {
          this.setErrorMessage(error);
        });
    },
  },
};
</script>
