<template>
  <div class="media-search-bar">
    <b-card class="mt-3">
      <b-form-group label="Titel des Films oder der Serie">
        <div class="d-flex">
          <b-input type="text" v-model="value" />

          <b-button class="ml-3" @click="$emit('input', value)">Suchen</b-button>
        </div>
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
// @ is an alias to /src
import settings from "@/utils/Settings";

export default {
  name: "MediaSearchBar",
  components: {},
  data() {
    return {
      value: "",
    }
  }
};
</script>
