<template>
  <div class="home">
    <h3 class="mb-3">Subvodio Dashboard</h3>
    <div class="flex-container mt-6">
      <b-card
        class="flex-item"
        title="Content pro Quelle"
        header-class="card-header"
        ><content-distribution v-if="status.media.netflix" :mediadata="mediaPerSource"
      /></b-card>
      <b-card class="flex-item" title="Registrierte User"
        ><registered-users
      /></b-card>
      <b-card
        v-if="statistics.length > 0"
        class="flex-item"
        title="Rohdaten vs. optimierte Daten"
        ><content-count-table :data="statistics"
      /></b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RegisteredUsers from "@/components/charts/RegisteredUsers";
import ContentDistribution from "@/components/charts/ContentDistribution";
import ContentCountTable from "@/components/ContentCountTable";

import SUBVODIO from "@/utils/AdminApi";

export default {
  name: "Home",
  components: {
    RegisteredUsers,
    ContentDistribution,
    ContentCountTable,
  },
  data() {
    return {
      statistics: [],
      status: { media: { meinvod: 1 } },
    };
  },

  mounted() {
    SUBVODIO.getStatus().then((response) => (this.status = response.data));
    SUBVODIO.getCountStatistics().then(
      (response) => (this.statistics = response.data)
    );
  },
  computed: {
    mediaPerSource() {
      if (this.status.media.meinvod == 1) {
        return {
          "MeinVOD": 1,
          "Netflix": 1,
          "Pantaflix": 1,
          "TV Now": 1,
          "Arte": 1,
        }  
      }
      return {
        "MeinVOD": this.status.media.meinvod,
        "Netflix": this.status.media.netflix,
        "Pantaflix": this.status.media.pantaflix,
        "TV Now": this.status.media.tvnow,
        "Arte": this.status.media.arte,
      }
    }
  }
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.flex-item {
  flex-grow: 1;
  max-width: 600px;
  max-height: 700px;
}

.card-title {
  text-align: center;
}

.card-body {
  background-color: rgba(240, 240, 240, 0.5);
  overflow: scroll;
}

.card {
  border: none;
}
</style>
