<template>
  <div class="login">
    <div class="page-container">
      <div class="login-container">
        <div class="img-container">
          <img src="images/icon.svg" alt="logo" />
          <h2>Subvodio Admin Portal</h2>
        </div>

        <b-form-group label="Email">
          <b-form-input v-model="formData.username"></b-form-input>
        </b-form-group>
        <b-form-group label="Passwort">
          <b-form-input
            @keydown.enter.native="login"
            type="password"
            v-model="formData.password"
          ></b-form-input>
        </b-form-group>

        <b-button variant="primary" @click="login">Login</b-button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SUBVODIO from "@/utils/AdminApi";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      formData: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      const userData = this.formData;
      SUBVODIO.login(userData.username, userData.password).catch((err) => {
        this.actionResult = {
          error: true,
          message: err,
        };
      });
    },
  },
};
</script>

<style scoped>
.page-container {
  display: flex;
  align-items: center;
  height: 100%;
}
.login-container {
  width: 400px;
  max-width: 90vw;
  margin: 2rem auto;
  padding: 2rem;
}

.img-container {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  height: 240px;
  margin-bottom: 1rem;
}

button {
  width: 100%;
  margin: 1rem 0;
}

button > a {
  color: white;
}

img {
  height: 100px;
}

h2 {
  color: var(--primary);
  margin: 1.2rem;
  text-align: center;
}
</style>
