import axios from "axios";
import router from "@/router/index";

const baseUrl = "https://subvodio-core.subvodio.dev";

const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const dataUrl = reader.result;
    resolve(dataUrl.replace(/^data:.*\/.*;base64,/, ""));
  }
  reader.onerror = error => reject(error);
});

axios.interceptors.request.use(
  config => {
    const token = SUBVODIO.user().access_token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      originalRequest.url === `${baseUrl}/refreshAuthorization/`
    ) {
      SUBVODIO.logout();
      return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return SUBVODIO.refresh(SUBVODIO.user().refresh_token).then(() => {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + SUBVODIO.user().access_token;
        return axios(originalRequest);
      });
    }
  }
);

const SUBVODIO = {
  // authentication
  user: () =>
    JSON.parse(
      sessionStorage.getItem("SUBVODIOUSER") ||
      JSON.stringify({
        expires_in: 0,
        access_token: "",
        refresh_token: "",
        name: "",
        isLoggedIn: false
      })
    ),
  login: (username, password) => {
    const params = new URLSearchParams();
    params.append("grant_type", "password");
    params.append("username", username);
    params.append("password", password);
    params.append("scope", "adminfe");
    const url = `${baseUrl}/authorize/`;
    return axios
      .post(url, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then(user => {
        SUBVODIO.getProfile(user.data.access_token).then(response => {
          user.data.isLoggedIn = true;
          user.data.username = response.data.username;
          user.data.id = response.data.id;
          user.data.role = response.data.role;

          sessionStorage.setItem("SUBVODIOUSER", JSON.stringify(user.data));
          window.dispatchEvent(new Event("SUBVODIOUSERCHANGE"));
          router.push("/");
        });

        return user;
      });
  },
  refresh: refresh_token => {
    const params = new URLSearchParams();
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", refresh_token);
    const url = `${baseUrl}/refreshAuthorization/`;
    return axios
      .post(url, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then(user => {
        user.data.isLoggedIn = true;
        user.data.username = SUBVODIO.user().username;
        user.data.id = SUBVODIO.user().id;
        user.data.role = SUBVODIO.user().role;

        sessionStorage.setItem("SUBVODIOUSER", JSON.stringify(user.data));
        window.dispatchEvent(new Event("SUBVODIOUSERCHANGE"));
        return user;
      });
  },
  logout: () => {
    sessionStorage.removeItem("SUBVODIOUSER");
    window.dispatchEvent(new Event("SUBVODIOUSERCHANGE"));
    localStorage.setItem("SUBVODIO_CREDENTIALS_FLUSH", "logout");
    localStorage.removeItem("SUBVODIO_CREDENTIALS_FLUSH", "logout");
    router.push("/login");
    return sessionStorage;
  },

  getStatus: () => {
    const url = `${baseUrl}/status`;
    return axios.get(url);
  },
  getCountStatistics: () => {
    const url = `${baseUrl}/media/statistics/count`;
    return axios.get(url);
  },

  getUserRoles: () => {
    const url = `${baseUrl}/users/roles`;
    return axios.get(url);
  },

  getProfile: token => {
    const bearerToken = token ? token : SUBVODIO.user().access_token;
    const url = `${baseUrl}/profile`;
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    });
  },
  // User management
  retrieveAllUsers: () => {
    const url = `${baseUrl}/users/`;
    return axios.get(url);
  },
  retrieveAllCoreUsers: () => {
    const url = `${baseUrl}/users/core`;
    return axios.get(url);
  },
  retrieveAllPortalUsers: () => {
    const url = `${baseUrl}/users/portal`;
    return axios.get(url);
  },
  retrieveUser: (userId) => {
    const url = `${baseUrl}/users/${userId}`;
    return axios.get(url);
  },

  persistUser: user => {
    const url = `${baseUrl}/users/`;
    return axios.put(url, user);
  },
  deleteCoreUser: (userId) => {
    const url = `${baseUrl}/users/core/${userId}`;
    return axios.delete(url);
  },
  deletePortalUser: (userId) => {
    const url = `${baseUrl}/users/portal/${userId}`;
    return axios.delete(url);
  },

  // MS management

  getMicroservices: () => {
    const url = `${baseUrl}/microservices`;
    return axios.get(url);
  },
  createOrUpdateMicroservices: ms => {
    const url = `${baseUrl}/microservices`;
    return axios.put(url, ms);
  },


  deleteMicroservice: id => {
    const url = `${baseUrl}/microservices/${id}`;
    return axios.delete(url);
  },
  getMsComponents: () => {
    const url = `${baseUrl}/components`;
    return axios.get(url);
  },
  registerOrUpdateMsComponent: componentToRegister => {
    const url = `${baseUrl}/components`;
    return axios.put(url, componentToRegister);
  },

  // Job management
  registerJob: jobToRegister => {
    const url = `${baseUrl}/scheduler/jobs`;
    return axios.post(url, jobToRegister);
  },
  registerQueue: queueToRegister => {
    const url = `${baseUrl}/scheduler/queues`;
    return axios.post(url, queueToRegister);
  },
  triggerJob: job => {
    const url = `${baseUrl}/scheduler/tasks`;
    return axios.post(url, job);
  },
  // getJobStatus: (jobId) => {
  //   const url = `${baseUrl}/jobs/${jobId}`;
  //   return axios
  //     .get(url)
  // },
  getJobQueue: () => {
    const url = `${baseUrl}/scheduler/tasks`;
    return axios.get(url);
  },
  displayJob: (queue, task) => {
    const url = `${baseUrl}/scheduler/tasks/${task}?queue=${queue}`;
    return axios.get(url);
  },
  cancelJob: (queue, task) => {
    const url = `${baseUrl}/scheduler/tasks/${task}?queue=${queue}`;
    return axios.delete(url);
  },
  clearJobQueue: (status) => {
    const url = `${baseUrl}/scheduler/queues/default?taskStatus=${status}`;
    return axios.delete(url);
  },
  getJobsAvailable: () => {
    const url = `${baseUrl}/scheduler/jobs`;
    return axios.get(url);
  },
  getQueuesAvailable: () => {
    const url = `${baseUrl}/scheduler/queues`;
    return axios.get(url);
  },

  // source management

  getSources: () => {
    const url = `${baseUrl}/sources`;
    return axios.get(url);
  },

  deactivateSource: (sourceId) => {
    const url = `${baseUrl}/sources/${sourceId}/deactivate`;
    return axios.post(url);
  },

  optimizeSource: (sourceId) => {
    const url = `${baseUrl}/sources/${sourceId}/optimize`;
    return axios.post(url);
  },

  activateSource: (sourceId) => {
    const url = `${baseUrl}/sources/${sourceId}/activate`;
    return axios.post(url);
  },

  createOrUpdateSource: source => {
    const url = `${baseUrl}/sources`;

    const copy = JSON.parse(JSON.stringify(source));
    copy.userschema = JSON.stringify(copy.userschema || {});
    copy.metaData = JSON.stringify(copy.metaData || {});

    if (source.logo !== undefined) {
      return fileToBase64(source.logo).then(logoBase64 => {
        copy.logo = logoBase64;
        return axios.put(url, copy);
      });
    }
    else {
      return axios.put(url, copy);
    }
  },
  getAllMedia: () => {
    const url = `${baseUrl}/media`;
    return axios
      .get(url)
  },
  getMediaByTitle: (title, page, fetchsize) => {
    const limit = fetchsize || 10;
    const pageNumber = page || 1;
    const url = `${baseUrl}/media?c=name|~|${title}&fetchsize=${limit}&page=${pageNumber}`;
    return axios
      .get(url)
  },
  getTopTenMedia: () => {
    const url = `${baseUrl}/media/topten`;
    return axios
      .get(url)
  },
  getOptimizedMedia: (source, sourceId) => {
    const url = `${baseUrl}/media/${source}/${sourceId}/details`;
    return axios
      .get(url)
  },

  getEditorLists: () => {
    const url = `${baseUrl}/media/editor/lists`;
    return axios.get(url);
  },
  persistEditorList: (data) => {
    const url = `${baseUrl}/editor/lists`;
    return axios
      .put(url, data)
  },
  deleteItemFromList: (params) => {
    const url = `${baseUrl}/editor/lists/${params[0]}/${params[1]}`;
    return axios
      .delete(url)
  },
  removeList: (listId) => {
    const url = `${baseUrl}/editor/lists/${listId}`;
    return axios
      .delete(url)
  }

};

export default SUBVODIO;
