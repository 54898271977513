<template>
  <div class="portal-users">
    <h3>Portal-User-Verwaltung</h3>
    <b-button
      class="mb-3"
      v-b-modal.user-form-modal
      @click="userData = { id: uuid(), role: 'user' }"
      >User hinzufügen</b-button
    >
    <UserTable :users="users" @delete="setUserToDelete" @edit="setUserToEdit" />

    <b-modal id="user-form-modal" centered :title="userFormTitle" hide-footer>
      <UserForm :userData="userData" role="user" @update="getUsers()" />
    </b-modal>
    <ConfirmationModal
      title="User löschen"
      @confirm="removeUser()"
      @hide="closeModal('confirm-modal')"
      centered
    />
  </div>
</template>

<script>
// @ is an alias to /src
import UserTable from "@/components/UserTable.vue";
import ConfirmationModal from "@/components/Base/ConfirmationModal.vue";
import UserForm from "@/components/UserForm.vue";
import SUBVODIO from "@/utils/AdminApi.js";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "PortalUsers",
  components: {
    UserTable,
    UserForm,
    ConfirmationModal,
  },

  data: () => {
    return {
      userData: { username: undefined },
      userToDelete: 0,
      fetchUsers: SUBVODIO.retrieveAllPortalUsers,
      users: [],
    };
  },
  computed: {
    userFormTitle() {
      return this.userData && this.userData.username === undefined
        ? "User anlegen"
        : `User ${this.userData.username} anpassen`;
    },
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "User nicht gespeichert"
        : "User erfolgreich gespeichert";
    },
  },

  mounted() {
    this.getUsers();
  },
  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },
    uuid() {
      return uuidv4();
    },
    closeModal(id) {
      this.$bvModal.hide(id);
    },

    setUserToDelete(id) {
      this.userToDelete = id;
    },
    setUserToEdit(data) {
      this.userData = data;
    },

    getUsers() {
      SUBVODIO.retrieveAllPortalUsers()
        .then((response) => {
          this.users = response.data;
        })
        .catch(() => {
          this.actionResult = {
            error: true,
            message: "User konnten nicht geladen werden.",
          };
          this.makeToast();
        });
    },

    removeUserFromList() {
      const indexToDelete = this.users.findIndex(
        (user) => user.id === this.userToDelete
      );
      this.users.splice(indexToDelete, 1);
    },
    removeUser() {
      SUBVODIO.deletePortalUser(this.userToDelete)
        .then(() => {
          this.removeUserFromList();
          this.actionResult = {
            error: false,
            message: "User erfolgreich gelöscht.",
          };
          this.makeToast();
        })
        .catch(() => {
          this.actionResult = {
            error: true,
            message: "User konnte nicht gelöscht werden.",
          };
          this.makeToast();
        });
      this.closeModal("confirm-modal");
    },
  },
};
</script>
