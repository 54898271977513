import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Scheduler from "../views/Scheduler.vue";
import Sources from "../views/Sources.vue";
import Login from "../views/Login.vue";
import Account from "../views/Account.vue";
import EditorLists from "../views/EditorLists.vue";
import MediaSearch from "../views/MediaSearch.vue";
import CoreUsers from "../views/CoreUsers.vue";
import PortalUsers from "../views/PortalUsers.vue";
import ProductBundles from "../views/ProductBundles.vue";
import Microservices from "../views/Microservices.vue";
import SUBVODIO from "../utils/AdminApi";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/scheduler",
    name: "scheduler",
    component: Scheduler
  },
  {
    path: "/sources",
    name: "sources",
    component: Sources
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/account",
    name: "account",
    component: Account
  },
  {
    path: "/editorlists",
    name: "editorlists",
    component: EditorLists
  },
  {
    path: "/mediasearch",
    name: "mediasearch",
    component: MediaSearch
  },
  {
    path: "/core-users",
    beforeEnter: (to, from, next) => {
      if (!(SUBVODIO.user().role === "admin")) next({ name: "home" });
      else next();
    },
    name: "coreUsers",
    component: CoreUsers
  },
  {
    path: "/portal-users",
    beforeEnter: (to, from, next) => {
      if (!(SUBVODIO.user().role === "admin")) next({ name: "home" });
      else next();
    },
    name: "portalUsers",
    component: PortalUsers
  },
  {
    path: "/product-bundles",
    name: "productBundles",
    component: ProductBundles
  },
  {
    path: "/microservices",
    beforeEnter: (to, from, next) => {
      if (!(SUBVODIO.user().role === "admin")) next({ name: "home" });
      else next();
    },
    name: "mircoservices",
    component: Microservices
  },
  {
    path: "/logout",
    beforeEnter: (to, from, next) => {
      SUBVODIO.logout();
      next("/login");
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !SUBVODIO.user().isLoggedIn)
    next({ name: "login" });
  else next();
});

export default router;
