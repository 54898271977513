<template>
  <div v-if="isNotLoginPage">
    <b-navbar toggleable type="light" variant="bg-medium">
      <div>
        <img src="images/icon.svg" alt="icon" />
        <b-navbar-brand href="/">SubvodioAdmin</b-navbar-brand>
      </div>
      <div class="d-flex align-items-center">
        <!-- <div class="username">{{ user.username || "" }}</div> -->
        <b-navbar-toggle
          target=""
          @click="setCollapsed('systemCollapsed')"
          v-if="userIsAdmin"
          class="mr-3"
        >
          <div>System</div>
        </b-navbar-toggle>

        <b-navbar-toggle
          target=""
          @click="setCollapsed('portalCollapsed')"
          class="mr-3"
        >
          <div>Portal</div>
        </b-navbar-toggle>

        <b-navbar-toggle target="" @click="setCollapsed('collapsed')">
          <b-icon v-if="collapsed" icon="chevron-bar-up"></b-icon>
          <b-icon v-else icon="chevron-bar-down"></b-icon>
        </b-navbar-toggle>
      </div>
      <b-collapse id="navbar-toggle-collapse" is-nav v-model="collapsed">
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="/account">Account</b-nav-item>
          <b-nav-item href="/logout">Logout</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <b-collapse
        id="navbar-toggle-collapse-system"
        is-nav
        v-model="systemCollapsed"
      >
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="/core-users">Core-User-Verwaltung</b-nav-item>
          <b-nav-item href="/portal-users">Portal-User-Verwaltung</b-nav-item>
          <b-nav-item href="/scheduler">Scheduler</b-nav-item>
          <b-nav-item href="/microservices">Microservices</b-nav-item>
          <b-nav-item href="/sources">Quellen</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <b-collapse
        id="navbar-toggle-collapse-portal"
        is-nav
        v-model="portalCollapsed"
      >
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="/product-bundles">Product Bundles</b-nav-item>
          <b-nav-item href="/editorlists">Redaktionelle Listen</b-nav-item>
          <b-nav-item href="/mediasearch">Mediensuche</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
// @ is an alias to /src
import SUBVODIO from "@/utils/AdminApi";

export default {
  name: "Navbar",
  components: {},
  data() {
    return {
      user: SUBVODIO.user(),
      portalCollapsed: false,
      systemCollapsed: false,
      collapsed: false,
    };
  },
  computed: {
    isNotLoginPage() {
      return this.$route.name !== "login";
    },
    userIsAdmin() {
      return this.user.role === "admin";
    },
  },
  created() {
    window.addEventListener("SUBVODIOUSERCHANGE", () => {
      this.user = SUBVODIO.user();
    });
  },

  methods: {
    setCollapsed(id) {
      switch (id) {
        case "collapsed":
          this.collapsed = !this.collapsed;
          this.systemCollapsed = false;
          this.portalCollapsed = false;
          break;
        case "systemCollapsed":
          this.collapsed = false;
          this.systemCollapsed = !this.systemCollapsed;
          this.portalCollapsed = false;
          break;
        case "portalCollapsed":
          this.collapsed = false;
          this.systemCollapsed = false;
          this.portalCollapsed = !this.portalCollapsed;
          break;
      }
    },
  },
};
</script>

<style scoped>
.nav-link {
  text-align: right;
}

a.navbar-brand {
  color: var(--primary);
  margin-left: 1rem;
}

.username {
  color: var(--primary);
  margin-right: 1rem;
}

img {
  height: 32px;
}

.navbar-toggler {
  border: none;
}
</style>
