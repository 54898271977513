<template>
  <div v-if="media" class="media-json-display">
    <b-card :title="media.name" class="mt-3">
      <MediaSourceButtons :finalized="media" @input="setMediaOptimized" />
      <pre>{{ display }}</pre>
    </b-card>
  </div>
</template>

<script>
import MediaSourceButtons from "@/components/MediaSourceButtons.vue";

export default {
  name: "MediaJsonDisplay",
  components: {MediaSourceButtons},
  props: {
    media: [Object, String]
  },
  data() {
    return { display: null };
  },
  methods: {
    setMediaOptimized(content) {
      this.display = content;
    }
  }
};
</script>

<style scoped>
.card-body {
  background-color: rgba(240, 240, 240, 1);
}
</style>