<template>
  <div class="media-source-buttons">
    <div class="d-flex">
      <b-button
          variant="primary"
          class="mr-2 mt-3"
          @click="$emit('input', finalized); showClose = true"
          >Finalized</b-button
        >
      <div v-for="source in finalized.sources" :key="source.srcId">
        <b-button
          variant="primary"
          v-if="source.src !== 'imdb'"
          class="mr-2 mt-3"
          @click="getOptimizedData(source)"
          >{{ source.src }}</b-button
        >
      </div>
      <b-button v-if="showClose"
          variant="outline-primary"
          class="mr-2 mt-3"
          @click="$emit('input', null); showClose = false"
          >Schließen</b-button
        >
    </div>
  </div>
</template>

<script>
import SUBVODIO from "@/utils/AdminApi";
import settings from "@/utils/Settings";

export default {
  name: "MediaSourceButtons",
  components: {},
  props: {
    finalized: Object,
  },
  data() {
    return {
      showClose: false
    }
  },
  methods: {
    getOptimizedData(source) {
      SUBVODIO.getOptimizedMedia(source.src, source.srcID).then((response) => {
        this.$emit("input", response.data);
        this.showClose = true;
      });
    },
  },
};
</script>
