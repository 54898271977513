<template>
  <div class="job-register-form">
    <div class="form-container">
      <b-form-group label="Job-ID">
        <b-form-input
          type="text"
          v-model="formData.id"
          :disabled="exisitingJob"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Start-URL">
        <b-form-input v-model="formData.startUrl"></b-form-input>
      </b-form-group>
      <b-form-group label="Status-URL">
        <b-form-input v-model="formData.statusUrl"></b-form-input>
      </b-form-group>
      <b-form-group label="Parameter Schema">
        <b-form-textarea v-model="formData.paramsSchema"></b-form-textarea>
      </b-form-group>

      <b-button class="float-right" @click="registerJob">Speichern</b-button>
    </div>
  </div>
</template>

<script>
import SUBVODIO from "@/utils/AdminApi";
import settings from "@/utils/Settings";

export default {
  name: "JobRegisterForm",
  components: {},
  props: {
    job: Object
  },

  data() {
    return {
      actionResult: {
        error: false,
        message: ""
      },
      formData: {},
      exisitingJob: false
    };
  },

  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "Job nicht gespeichert"
        : "Job erfolgreich gespeichert";
    }
  },

  mounted() {
    if (this.job) {
      this.formData = this.job;
      if (this.job.id) {
        this.exisitingJob = true;
      }
    }
  },
  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true
      });
    },

    registerJob() {
      SUBVODIO.registerJob(this.formData)
        .then(() => {
          this.actionResult = {
            error: false,
            message:
              "Job am " +
              new Date().toLocaleString(undefined, settings.dateFormat) +
              " gespeichert."
          };
          this.makeToast();
          this.$emit("update");
          this.$bvModal.hide("register-job-modal");
        })
        .catch(error => {
          this.actionResult = {
            error: true,
            message: "Es gab einen Fehler bei der Bearbeitung: " + error.message
          };
          this.makeToast();
        });
    }
  }
};
</script>

<style scoped>
button {
  margin: 1.4rem 0;
}

.card-body {
  padding: 0;
}

.form-container {
  padding: 1.4rem;
}

.card-header {
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
}

.card {
  margin: 2rem 0;
}
</style>
