<template>
  <div id="app">
    <div id="nav">
      <Navbar />
    </div>
    <div class="content-container">
      <router-view />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Base/Navbar.vue";
export default {
  name: "App",
  components: {
    Navbar,
  },
  data() {
    return {};
  },
  created() {
    window.addEventListener("storage", (event) => {
      const user = SUBVODIO.user();
      if (
        event.key === "SUBVODIO_REQUESTING_SHARED_CREDENTIALS" &&
        user.isLoggedIn
      ) {
        window.localStorage.setItem(
          "SUBVODIO_CREDENTIALS_SHARING",
          JSON.stringify(user)
        );
        window.localStorage.removeItem("SUBVODIO_CREDENTIALS_SHARING");
      }
      if (event.key === "SUBVODIO_CREDENTIALS_SHARING" && !user.isLoggedIn) {
        window.sessionStorage.setItem("SUBVODIOUSER", event.newValue);
        window.dispatchEvent(new Event("SUBVODIOUSERCHANGE"));
      }
      if (event.key === "SUBVODIO_CREDENTIALS_FLUSH" && user.isLoggedIn) {
        SUBVODIO.logout();
      }
    });
  },
};
</script>

<style lang="scss">
</style>
