<template>
  <div class="source-register-form">
    <div class="form-container">
      <b-form-group label="Id">
        <b-form-input v-model="formData.id" type="text"></b-form-input>
      </b-form-group>
      <b-form-group label="Name">
        <b-form-input v-model="formData.name"></b-form-input>
      </b-form-group>
      <b-form-group label="Homepage">
        <b-form-input v-model="formData.homepage"></b-form-input>
      </b-form-group>
      <b-form-group label="Kurzbeschreibung">
        <b-form-input v-model="formData.subtitle"></b-form-input>
      </b-form-group>
      <b-form-group label="Logo">
        <b-form-file v-model="formData.logo"></b-form-file>
      </b-form-group>
      <b-form-group label="Typ">
        <b-form-select
          v-model="formData.type"
          :options="sourceTypes"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="Datenbank URI für optimierte Daten">
        <b-form-input v-model="formData.dbUri"></b-form-input>
      </b-form-group>
      <b-form-group label="Datenbank">
        <b-form-input v-model="formData.dbName"></b-form-input>
      </b-form-group>
      <b-form-group label="Kollektion">
        <b-form-input v-model="formData.dbCollectionName"></b-form-input>
      </b-form-group>
      <b-form-group label="User Schema">
        <vue-json-editor
          v-model="formData.userschema"
          :show-btns="false"
          mode="code"
          :expandedOnStart="true"
        />
      </b-form-group>
      <b-form-group label="Meta-Data">
        <vue-json-editor
          v-model="formData.metaData"
          :show-btns="false"
          mode="code"
          :expandedOnStart="true"
        />
      </b-form-group>
      <b-form-group
        v-if="formData.type === 'subscribable'"
        label="Preisgestaltung Subscription"
      >
        <vue-json-editor
          v-model="formData.subscriptionOffers"
          :show-btns="false"
          mode="code"
          :expandedOnStart="true"
        />
      </b-form-group>
      <b-button class="float-right" @click="persistSource"
        >Quelle registrieren</b-button
      >
    </div>
  </div>
</template>

<script>
import SUBVODIO from "@/utils/AdminApi";
import settings from "@/utils/Settings";
import { v4 as uuidv4 } from "uuid";
import VueJsonEditor from "vue-json-editor";

export default {
  name: "SourceRegisterForm",
  components: { VueJsonEditor },
  props: {
    source: Object,
  },
  data() {
    return {
      actionResult: {
        error: false,
        message: "",
      },
      formData: { active: false },
      component: "",
      componentOptions: ["Standard", "One-Time-Queue"],
      sourceTypes: ["accountless", "free", "transactional", "subscribable"],
    };
  },

  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "Microservice nicht gespeichert"
        : "Microservice erfolgreich gespeichert";
    },
  },

  mounted() {
    if (this.source) {
      this.formData = JSON.parse(JSON.stringify(this.source));
      if (this.source.logo !== undefined) {
        const fileBase64 = atob(this.source.logo);
        const u8arr = new Uint8Array(fileBase64.length);
        for (var i = 0; i < fileBase64.length; i++) {
          u8arr[i] = fileBase64.charCodeAt(i);
        }

        this.formData.logo = new File([u8arr], "logo.png", {
          type: "image/png",
        });
      }

      if (this.source.userschema) {
        this.formData.userschema = JSON.parse(this.source.userschema);
      }

      if (this.source.subscriptionOffers) {
        this.formData.subscriptionOffers = JSON.parse(
          this.source.subscriptionOffers
        );
      }
    } else {
      this.formData = {
        id: uuidv4(),
        active: false,
        userschema: {},
        metaData: {},
        subscriptionOffers: {},
      };
      this.component = "";
    }
  },

  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },

    setSuccessMessage(type) {
      this.actionResult = {
        error: false,
        message:
          type +
          " am " +
          new Date().toLocaleString(undefined, settings.dateFormat) +
          " geladen.",
      };
      this.makeToast();
    },
    setErrorMessage(error) {
      this.actionResult = {
        error: true,
        message: "Es gab einen Fehler bei der Bearbeitung: " + error.message,
      };
      this.makeToast();
    },

    persistSource() {
      if (this.formData.id !== undefined && this.formData.dbUri !== undefined) {
        this.formData.subscriptionOffers = JSON.stringify(
          this.formData.subscriptionOffers
        );
        SUBVODIO.createOrUpdateSource(this.formData)
          .then(() => {
            this.setSuccessMessage("Quelle");
            this.$emit("update");
          })
          .catch(this.setErrorMessage(err));
      } else {
        this.actionResult = {
          error: true,
          message:
            "Es gab einen Fehler bei der Bearbeitung: Quelle kann nicht erzeugt werden.",
        };
        this.makeToast();
      }
    },
  },
};
</script>

<style scoped>
button {
  margin: 1.4rem 0;
}

.card-body {
  padding: 0;
}

.form-container {
  padding: 1.4rem;
}

.card-header {
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
}

.card {
  margin: 2rem 0;
}
</style>
