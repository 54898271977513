<template>
  <div class="small">
    <line-chart :chart-data="datacollection"></line-chart>
  </div>
</template>

<script>
import LineChart from "./LineChart.js";

export default {
  name: "RegisteredUsers",
  components: {
    LineChart,
  },
  data() {
    return {
      datacollection: null,
      memory: 3,
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: [
          "Januar",
          "Februar",
          "März",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
        ],
        datasets: [
          {
            backgroundColor: "#21304A",
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
            ],
          },
        ],
      };
    },
    getRandomInt() {
      this.memory += Math.floor(Math.random() * (50 - 5 + 1)) + 5;
      return this.memory;
    },
  },
};
</script>

<style>
.small {
  max-width: 420px;
  margin: 150px auto;
}
</style>
