<template>
  <div class="small">
    <pie-chart :chart-data="datacollection"></pie-chart>
  </div>
</template>

<script>
import PieChart from "./PieChart.js";

export default {
  name: "ContentDistribution",
  props: {
    mediadata: Object
  },
  components: {
    PieChart,
  },
  data() {
    return {
      datacollection: null,
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      let labels = ["PantaFlix", "Netflix", "MeinVOD", "Arte"];
      let data = [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
            ];
      let backgoundColors = ["#fdae61", "#931A87", "#21304A", "#abdda4", "ff0011"];

      if (this.mediadata) {
        labels = Object.keys(this.mediadata);
        data = labels.map(k => this.mediadata[k]);
      }

      this.datacollection = {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgoundColors,
          },
        ],
      };
    },
    getRandomInt() {
      return Math.floor(Math.random() * 50000) + 5;
    },
  },
};
</script>

<style>
.small {
  max-width: 420px;
  margin: 50px auto;
}
</style>
