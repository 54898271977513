<template>
  <div class="info-modal">
    <b-modal :title="title" id="info-modal" size="xl" centered hide-footer>
      <div v-if="wrapWithPre">
        <pre>{{ content }}</pre> 
      </div>
      <div v-else>
      {{ content }}
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "InfoModal",
  components: {},

  props: {
    title: { type: String, default: "Info" },
    content: {
      type: [String, Object],
      default: "placeholder"
    },
    wrapWithPre: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    doAction() {
      this.$emit("confirm");
    }
  }
};
</script>

<style scoped>
button {
  width: 64px;
}
</style>
