<template>
  <div class="ms-register-form">
    <div class="form-container">
      <b-form-group label="Id">
        <b-form-input v-model="formData.id" type="text" disabled></b-form-input>
      </b-form-group>
      <b-form-group label="Name">
        <b-form-input v-model="formData.name"></b-form-input>
      </b-form-group>
      <b-form-group label="Base-URL">
        <b-form-input v-model="formData.baseUrl"></b-form-input>
      </b-form-group>
      <b-form-group label="Middleware-Komponente">
        <b-form-select
          v-model="component"
          :options="['', 'scheduler', 'dataImporter', 'userMngmt']"
        ></b-form-select>
      </b-form-group>
      <b-button class="float-right" @click="persistMicroservice"
        >Microservice registrieren</b-button
      >
    </div>
  </div>
</template>

<script>
import SUBVODIO from "@/utils/AdminApi";
import settings from "@/utils/Settings";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "MsRegisterForm",
  components: {},
  props: {
    ms: Object,
  },
  data() {
    return {
      actionResult: {
        error: false,
        message: "",
      },
      formData: {},
      component: "",
      componentOptions: ["Standard", "One-Time-Queue"],
    };
  },

  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "Microservice nicht gespeichert"
        : "Microservice erfolgreich gespeichert";
    },
  },

  mounted() {
    if (this.ms) {
      this.formData = this.ms;
      this.component = this.ms.component;
    } else {
      this.formData = { id: uuidv4() };
      this.component = "";
    }

    this.getComponentOptions();
  },

  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },

    setSuccessMessage(type) {
      this.actionResult = {
        error: false,
        message:
          type +
          " am " +
          new Date().toLocaleString(undefined, settings.dateFormat) +
          " geladen.",
      };
      this.makeToast();
    },
    setErrorMessage(error) {
      this.actionResult = {
        error: true,
        message: "Es gab einen Fehler bei der Bearbeitung: " + error.message,
      };
      this.makeToast();
    },

    getComponentOptions() {
      SUBVODIO.getMsComponents().then((response) => {
        this.componentOptions = response.data;
      });
    },

    persistMicroservice() {
      if (this.formData.id !== undefined) {
        SUBVODIO.createOrUpdateMicroservices(
          this.formData,
          this.component
        ).then(() => {
          this.actionResult = {
            error: false,
            message:
              "Job am " +
              new Date().toLocaleString(undefined, settings.dateFormat) +
              " gespeichert.",
          };
          this.makeToast();
          this.$emit("update");
        });
      } else {
        this.actionResult = {
          error: true,
          message:
            "Es gab einen Fehler bei der Bearbeitung: Job muss ausgewählt werden.",
        };
        this.makeToast();
      }
    },
  },
};
</script>

<style scoped>
button {
  margin: 1.4rem 0;
}

.card-body {
  padding: 0;
}

.form-container {
  padding: 1.4rem;
}

.card-header {
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
}

.card {
  margin: 2rem 0;
}
</style>
