<template>
  <div class="account">
    <h3>Profildaten ändern</h3>
    <UserForm :userData="user" />
  </div>
</template>

<script>
// @ is an alias to /src
import UserForm from "@/components/UserForm.vue";
import SUBVODIO from "@/utils/AdminApi";

export default {
  name: "Account",
  components: {
    UserForm,
  },
  data() {
    return {
      user: SUBVODIO.user(),
    };
  },
};
</script>
