<template>
  <div class="job-status-list" :key="updateCounter">
    <b-button class="mb-3" @click="getJobQueue">Aktualisieren</b-button>
    <b-button class="mb-3" @click="clearJobQueue('finished')"
      >Abgeschlossene Jobs löschen</b-button
    >
    <b-button class="mb-3" @click="clearJobQueue('FAILED')"
      >Fehlgeschlagene Jobs löschen</b-button
    >
    <Table :items="items" :header="header" sortBy="startDate" :sortDesc="true">
      <template v-slot:cell(link)="data">
        <div class="icon-container">
          <img
            src="/images/delete.svg"
            alt="play"
            @click="cancelJob(data.item)"
          />
          <img
            src="images/edit.svg"
            alt="edit"
            @click="displayJob(data.item)"
          />
        </div>
      </template>
    </Table>
    <ConfirmationModal
      title="Zeit löschen"
      @confirm="deleteActivity()"
      @hide="closeModal('confirm-modal')"
    />
    <InfoModal
      :wrapWithPre="true"
      title="Task Details"
      :content="infoContent"
      @hide="closeModal('info-modal')"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Table from "@/components/Base/Table.vue";
import ConfirmationModal from "@/components/Base/ConfirmationModal.vue";
import InfoModal from "@/components/Base/InfoModal.vue";
import SUBVODIO from "@/utils/AdminApi";
import settings from "@/utils/Settings";

export default {
  name: "JobStatus",
  components: {
    Table,
    ConfirmationModal,
    InfoModal,
  },
  props: {
    updateCounter: Number,
  },
  data() {
    return {
      queue: [],
      infoContent: "",
      header: [
        {
          key: "queue",
          label: "Queue",
          sortable: true,
        },
        {
          key: "id",
          label: "Job-ID",
          sortable: true,
        },
        {
          key: "job",
          label: "Job",
          sortable: true,
        },
        {
          key: "startedAt",
          label: "Start-Datum",
          sortable: true,
        },
        // {
        //   key: "endDate",
        //   label: "End-Datum",
        //   sortable: true
        // },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        { key: "link", label: "Aktionen", sortable: false },
      ],
    };
  },
  computed: {
    items() {
      return this.queue;
    },
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "Jobs nicht bearbeitet"
        : "Jobs erfolgreich bearbeitet";
    },
  },
  watch: {
    updateCounter: {
      handler() {
        this.getJobQueue();
      },
      immediate: true,
    },
  },
  mounted() {
    this.getJobQueue();
  },
  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },
    closeModal(modalId) {
      this.$bvModal.hide(modalId);
    },
    clearJobQueue(status) {
      SUBVODIO.clearJobQueue(status).then(this.getJobQueue);
    },
    cancelJob(job) {
      SUBVODIO.cancelJob(job.queue, job.jobId).then(this.getJobQueue);
    },
    displayJob(job) {
      SUBVODIO.displayJob(job.queue, job.jobId).then((response) => {
        const json = response.data;
        if ("response" in json) {
          try {
            const jsonResponse = JSON.parse(json.response);
            json.response = jsonResponse;
          } catch {
            //nothing to do
          }
        }

        this.infoContent = JSON.stringify(json, null, "  ");

        this.$bvModal.show("info-modal");
      });
    },
    getJobQueue() {
      SUBVODIO.getJobQueue()
        .then((response) => {
          this.queue = Object.keys(response.data).reduce(
            (jobs, queueName) =>
              jobs.concat(
                response.data[queueName].map((j) => ({
                  id: j.jobId,
                  queue: queueName,
                  ...j,
                }))
              ),
            []
          );
          this.actionResult = {
            error: false,
            message:
              "Jobs am " +
              new Date().toLocaleString(undefined, settings.dateFormat) +
              " geladen.",
          };
          // this.makeToast();
        })
        .catch((error) => {
          this.actionResult = {
            error: true,
            message:
              "Es gab einen Fehler bei der Bearbeitung: " + error.message,
          };
          this.makeToast();
        });
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.table-container {
  padding: 1.2rem;
}

.icon-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 0.2rem;
}
</style>
