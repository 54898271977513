<template>
  <div class="user-form">
    <div class="form-container">
      <b-form-group label="E-Mail">
        <b-form-input type="text" v-model="formData.username"></b-form-input>
      </b-form-group>
      <b-form-group v-if="user.role === 'admin'" label="Level">
        <b-form-select
          :options="roleOptions"
          v-model="formData.role"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="Neues Passwort">
        <b-form-input
          v-model="formData.passwordFirst"
          type="password"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Passwort bestätigen">
        <b-form-input
          v-model="formData.passwordSecond"
          type="password"
        ></b-form-input>
      </b-form-group>
      <b-button class="float-right" @click="updateUser">Speichern</b-button>
    </div>
  </div>
</template>

<script>
import SUBVODIO from "@/utils/AdminApi";
import settings from "@/utils/Settings";

export default {
  name: "UserForm",
  components: {},
  props: {
    userData: { type: Object },
    role: { type: String, default: "" },
  },
  data() {
    return {
      user: SUBVODIO.user(),
      actionResult: {
        error: false,
        message: "",
      },
      formData: {},
      roleOptions: [
        { value: "admin", text: "Admin" },
        { value: "extern", text: "Extern" },
        { value: "editor", text: "Editor" },
        { value: "frontend", text: "Frontend" },
      ],
    };
  },

  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "User nicht gespeichert"
        : "User erfolgreich gespeichert";
    },
  },

  mounted() {
    SUBVODIO.getUserRoles().then(response => {
      this.roleOptions = response.data.map(role => {
        const option = {}
        option.value = role;
        option.text = role.charAt(0).toUpperCase() + role.slice(1);

        return option;
      });
    });

    if (this.userData) {
      this.formData = this.userData;
    } else {
      if (this.user) {
        this.formData.username = this.user.username;
        this.formData.id = this.user.id;
      }
    }

    if (this.role == "user") {
      this.roleOptions = [{ value: "user", text: "User" }];
    }
  },

  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },

    updateUser() {
      if (this.formData.passwordFirst === this.formData.passwordSecond) {
        const user = {
          id: this.formData.id,
        };
        if (this.formData.role !== undefined) {
          user.role = this.formData.role;
        }
        if (this.formData.username && this.formData.username.length > 3) {
          user.username = this.formData.username;
        }
        if (
          this.formData.passwordFirst &&
          this.formData.passwordFirst.length > 4
        ) {
          user.password = this.formData.passwordFirst;
        }
        SUBVODIO.persistUser(user).then(() => {
          this.actionResult = {
            error: false,
            message:
              "User am " +
              new Date().toLocaleString(undefined, settings.DateFormat) +
              " gespeichert.",
          };
          this.makeToast();
          this.$bvModal.hide("user-form-modal");
          this.$emit("update");
        });
      } else {
        this.actionResult = {
          error: true,
          message:
            "Es gab einen Fehler bei der Bearbeitung: Die eingegebenen Passwörter stimmen nicht überein. ",
        };
        this.makeToast();
      }
    },
  },
};
</script>

<style scoped>
button {
  margin: 1.4rem 0;
}

.card-body {
  padding: 0;
}

.form-container {
  padding: 1.4rem;
}

.card-header {
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
}

.card {
  margin: 2rem 0;
}
</style>
