<template>
  <div class="registered-items-list">
    <b-tabs content-class="my-3">
      <b-tab title="Microservices" active>
        <Table :items="items" :header="headerMs" :showPagination="false">
          <template v-slot:cell(link)="data">
            <img
              src="/images/edit.svg"
              alt="play"
              v-b-modal.register-ms-modal
              @click="msToEdit = data.item"
            />
          </template>
        </Table>
        <b-button
          v-b-modal.register-ms-modal
          @click="msToEdit = undefined"
          class="my-3"
          >Microservice registrieren</b-button
        >
      </b-tab>
      <!-- <b-tab title="Komponenten">
        <Table :items="queues" :header="headerQueue" :showPagination="false">
        </Table>
        <b-button v-b-modal.register-queue-modal class="ml-3"
          >Queue registrieren</b-button
        >
      </b-tab> -->
    </b-tabs>

    <b-modal
      id="register-ms-modal"
      hide-footer
      centered
      title="Microservice registrieren"
    >
      <MsRegisterForm :ms="msToEdit" />
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Table from "@/components/Base/Table.vue";
import MsRegisterForm from "@/components/MsRegisterForm.vue";
import QueueRegisterForm from "@/components/QueueRegisterForm.vue";
import JobRegisterForm from "@/components/JobRegisterForm.vue";
import SUBVODIO from "@/utils/AdminApi";
import settings from "@/utils/Settings";

export default {
  name: "MicroservicesList",
  components: {
    Table,
    MsRegisterForm,
  },
  props: {
    entries: {
      type: Array,
      default: () => {
        return [
          {
            id: "123-3454-55635654-5636",
            name: "Scheduler",
            baseUrl: "Scheduler.com",
            component: "scheduler",
          },
          {
            id: "123-3454-55635654-5636",
            name: "Data Importer",
            baseUrl: "data.com",
            component: "importer",
          },
          {
            id: "123-3454-55635654-5636",
            name: "User management",
            baseUrl: "users.com",
            component: "userMngmt",
          },
        ];
      },
    },
  },
  data() {
    return {
      headerMs: [
        {
          key: "id",
          label: "Id",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "baseUrl",
          label: "Basis-Url",
          sortable: true,
        },
        {
          key: "component",
          label: "Middleware-Komponente",
          sortable: true,
        },
        { key: "link", label: "Aktionen", sortable: false },
      ],
      headerQueue: [
        {
          key: "id",
          label: "Queue",
          sortable: true,
        },
        {
          key: "type",
          label: "Typ",
          sortable: true,
        },
      ],
      msToEdit: {},
      items: [],
    };
  },
  computed: {
    // TODO: replace the computed properties with data received from BE

    componentToEdit() {
      return this.msToEdit ? this.msToEdit.component : "";
    },

    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "Eintrag nicht bearbeitet"
        : "Eintrag erfolgreich bearbeitet";
    },
  },

  mounted() {
    this.getMsList();
  },
  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },
    closeModal() {
      this.$bvModal.hide("confirm-modal");
    },

    setSuccessMessage(type) {
      this.actionResult = {
        error: false,
        message:
          type +
          " am " +
          new Date().toLocaleString(undefined, settings.dateFormat) +
          " geladen.",
      };
      this.makeToast();
    },
    setErrorMessage(error) {
      this.actionResult = {
        error: true,
        message: "Es gab einen Fehler bei der Bearbeitung: " + error.message,
      };
      this.makeToast();
    },

    getMsList() {
      SUBVODIO.getMicroservices()
        .then((response) => {
          this.items = response.data;
          this.setSuccessMessage("Mircoservices");
        })
        .catch((error) => this.setErrorMessage(error));
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.table-container {
  padding: 1.2rem;
}

.icon-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 0.2rem;
}
</style>
