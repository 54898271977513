<template>
  <div class="media-search">
    <h3>Mediensuche</h3>
    <MediaSearchBar @input="setMediaFinalized" />
    <MediaJsonDisplay v-for="mediaEntry in media" :key="mediaEntry.subvodioId" :media="mediaEntry" />
    <b-button v-if="lookForMore" @click="loadMore" variant="primary">Mehr...</b-button>
  </div>
</template>

<script>
import MediaSearchBar from "@/components/MediaSearchBar.vue";
import MediaJsonDisplay from "@/components/MediaJsonDisplay.vue";

import SUBVODIO from "@/utils/AdminApi";

export default {
  name: "MediaSearch",
  components: {
    MediaSearchBar,
    MediaJsonDisplay
  },
  data() {
    return {
      media: [],
      query: "",
      page: 1,
      lookForMore: false
    };
  },
  methods: {
    setMediaFinalized(searchText) {
      this.query = searchText;
      this.page = 1;
      this.media = [];
      this.loadMore();
    },
    loadMore() {
      const pageSize = 10;
      SUBVODIO.getMediaByTitle(this.query, this.page, pageSize)
        .then((response) => {
          const result =
            response.data.length > 0 ? response.data : "Titel nicht gefunden";
          this.media = this.media.concat(result);
          if (result.length == pageSize) {
            this.lookForMore = true;
            this.page = this.page + 1;
          }
          else {
            this.lookForMore = false;
          }
        })
        .catch((err) => console.log(err));
    }
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}

.flex-item {
  flex-grow: 1;
}
</style>