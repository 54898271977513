<template>
  <div class="table">
    <div
      v-if="showPagination"
      class="d-flex align-items-center my-3 justify-content-end"
    >
      <div class="d-flex align-items-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="results-table"
          id="paginator"
          size="sm"
        ></b-pagination>
        <b class="mx-3">Seite {{ currentPage }} von {{ totalPages }}</b>
      </div>
    </div>
    <div>
      <b-table
        id="results-table"
        :items="filtered"
        :fields="header"
        :sort-desc.sync="sortDesc"
        :per-page="perPage"
        :current-page="currentPage"
        sort-icon-left
        responsive
        striped
        hover
        :sortBy="sortBy"
        sort-compare-locale="de"
      >
        <template
          v-if="header.some((c) => c.sortable)"
          slot="top-row"
          slot-scope="{ fields }"
        >
          <td v-for="(field, index) in fields" :key="field.key">
            <div v-if="field.sortable">
              <input
                v-model="filters[field.key]"
                :placeholder="field.label"
                class="form-control"
              />
            </div>
            <div
              v-if="fields.length - 1 === index && !field.sortable"
              @click="filters = {}"
              class="img-container"
            >
              <img src="images/delete.svg" alt="Filter löschen" />
            </div>
          </td>
        </template>
        <template v-for="(index, name) in $slots" v-slot:[name]>
          <slot :name="name" />
        </template>
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data"></slot>
        </template>
      </b-table>
    </div>
    <div v-if="isLoading" class="text-center mt-3">
      <LoadingSpinner />
    </div>
    <div v-else-if="filtered.length === 0" class="text-center">
      <b>{{ noResultsInfo }}</b>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Base/LoadingSpinner";

export default {
  name: "Table",
  components: {
    LoadingSpinner,
  },

  props: {
    initialPage: { type: Number, default: 1 },
    items: Array,
    header: Array,
    isLoading: Boolean,
    noOfRows: { type: Number, default: 0 },
    sortBy: String,
    sortDesc: { type: Boolean, default: true },
    showPagination: { type: Boolean, default: true },
  },
  data() {
    return {
      filters: {},
      currentPage2: this.initialPage,
      currentPage: this.initialPage,
      waitingInfo: "Daten werden geladen...",
      noResultsInfo: "Es wurden keine Daten gefunden.",
      pattern: "#,##0.00",
      locale: "en_US",
      unit: "€",
    };
  },
  watch: {
    isLoading: function() {
      this.currentPage = 1;
      this.$nextTick(() => (this.currentPage = this.initialPage));
    },
    initialPage: function(newPage) {
      this.currentPage = newPage || 1;
    },
  },
  computed: {
    perPage() {
      return this.noOfRows === 0 ? 10 : this.noOfRows;
    },

    totalPages() {
      return Math.ceil(this.rows / this.perPage);
    },

    rows() {
      return this.filtered.length;
    },

    actionLinkOne() {
      const headerFound = this.header.find((h) => h.key === "link");
      return headerFound === undefined ? "" : headerFound.label;
    },

    actionLinkTwo() {
      const headerFound = this.header.find((h) => h.key === "link2");
      return headerFound === undefined ? "" : headerFound.label;
    },
    filtered() {
      if (this.items.length > 0) {
        const filtered = this.items.filter((item) => {
          return Object.keys(this.filters).every((key) => {
            const formatter = this.getFormatter(key);
            const value = formatter(item[key]);
            return String(value)
              .toLowerCase()
              .includes(this.filters[key].toLowerCase());
          });
        });
        return filtered;
      }
      return [];
    },
  },
  methods: {
    getFormatter(key) {
      const field = this.header.find((head) => head.key === key);
      return field.formatter ? field.formatter : this.defaultFormatter;
    },

    defaultFormatter(value) {
      return value;
    },
  },
};
</script>

<style scoped>
h5 {
  margin: 1.6rem 0;
  font-weight: 600;
}
.img-container {
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

ul.pagination > li.page-item.active > button.page-link {
  background-color: var(--bg-highlight);
}

.paginator > .page-item.active > .page-link.bg-primary {
  background-color: var(--bg-highlight);
}
</style>
