<template>
  <div class="user-table">
    <Table :items="users" :header="header">
      <template v-slot:cell(link)="data">
        <div class="icon-container">
          <img
            src="/images/delete.svg"
            alt="delete"
            v-b-modal.confirm-modal
            @click="$emit('delete', data.item.id)"
          />
          <img
            src="images/edit.svg"
            alt="edit"
            v-b-modal.user-form-modal
            @click="$emit('edit', data.item)"
          />
        </div>
      </template>
      <template v-slot:cell(sourceUsers)="data">
        <div v-for="source in data.item.sourceUsers" :key="source.sourceId">
          <div
            class="badge badge-primary m-1"
            @click="
              selectedSourceUser = source;
              selectedUser = data.item;
            "
            v-b-modal.source-user-modal
          >
            {{ source.sourceId }}
          </div>
        </div>
      </template>
    </Table>
    <b-modal
      id="source-user-modal"
      hide-footer
      centered
      :title="sourceUserTitle"
      size="xl"
      scrollable
    >
      <pre> {{ selectedSourceUser }}</pre>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/components/Base/Table.vue";
import SUBVODIO from "@/utils/AdminApi";
import settings from "@/utils/Settings";

export default {
  name: "UserTable",
  props: {
    fetchUsers: {
      type: Function,
      default: () => [],
    },
    users: { type: Array, default: () => [] },
    type: { type: String, default: "portal" },
  },
  components: {
    Table,
  },
  data: () => {
    return {
      selectedSourceUser: {},
      selectedUser: {},
      header: [
        {
          key: "username",
          label: "Username",
          sortable: true,
        },
        {
          key: "id",
          label: "User-ID",
          sortable: true,
        },
        {
          key: "role",
          label: "Rolle",
          sortable: true,
        },
        {
          key: "sourceUsers",
          label: "Source User",
          sortable: false,
        },
        { key: "link", label: "Aktionen", sortable: false },
      ],
    };
  },
  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "User nicht gespeichert"
        : "User erfolgreich gespeichert";
    },

    sourceUserTitle() {
      return `${this.selectedSourceUser.sourceId} Account von ${this.selectedUser.username}`;
    },
  },

  mounted() {
    this.removeSourceUserColumnIfCoreTable();
  },

  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },

    removeSourceUserColumnIfCoreTable() {
      if (this.type == "core") {
        const index = this.header.findIndex(
          (item) => item.key == "sourceUsers"
        );
        this.header.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
.icon-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 0.2rem;
  max-width: 80px;
}
</style>
