<template>
  <div class="microservices">
    <h3>Microservices</h3>
    <MicroservicesList />
  </div>
</template>

<script>
// @ is an alias to /src
// import RegisteredItemsList from "@/components/RegisteredItemsList.vue";
// import JobStatus from "@/components/JobStatus.vue";
import MicroservicesList from "@/components/MicroservicesList.vue";

export default {
  name: "Microservices",
  components: {
    // RegisteredItemsList,
    // JobStatus,
    MicroservicesList,
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}

.flex-item {
  flex-grow: 1;
}

/* .flex-item:first-child {
  max-width: 500px;
} */
</style>