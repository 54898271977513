<template>
  <div class="scheduler">
    <div class="flex-container">
      <b-card class="flex-item" title="Registrierte Jobs und Queues"
        ><RegisteredItemsList
      /></b-card>
      <b-card class="flex-item" title="Status laufender Jobs"
        ><JobStatus
      /></b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RegisteredItemsList from "@/components/RegisteredItemsList.vue";
import JobStatus from "@/components/JobStatus.vue";

export default {
  name: "Scheduler",
  components: {
    RegisteredItemsList,
    JobStatus,
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}

.flex-item {
  flex-grow: 1;
}
</style>