<template>
  <div class="job-start-form">
    <div class="form-container">
      <b-form-group label="Id">
        <b-form-input v-model="formData.id" type="text" disabled></b-form-input>
      </b-form-group>
      <b-form-group label="Job">
        <v-select v-model="formData.job" :options="jobOptions"></v-select>
      </b-form-group>
      <b-form-group label="Queue">
        <v-select v-model="formData.queue" :options="queueOptions"></v-select>
      </b-form-group>
      <div v-if="schema">
        <FormSchema
          :schema="schema"
          v-model="formData.params"
          input-wrapping-class="param-elem"
        >
          <small>generated from json schema</small>
        </FormSchema>
      </div>

      <b-button class="float-right" @click="triggerJob">Job triggern</b-button>
    </div>
  </div>
</template>

<script>
import SUBVODIO from "@/utils/AdminApi";
import settings from "@/utils/Settings";

import FormSchema from "@formschema/native";

import { v4 as uuidv4 } from "uuid";

export default {
  name: "JobStartForm",
  components: { FormSchema },
  props: {
    job: Object,
    jobOptions: Array,
    queueOptions: Array
  },
  data() {
    return {
      actionResult: {
        error: false,
        message: ""
      },
      formData: {
        id: uuidv4(),
        job: "Data Import MeinVOD",
        queue: this.queueOptions[0]
      },
      schema: null
    };
  },

  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "Job nicht gespeichert"
        : "Job erfolgreich gespeichert";
    }
  },

  mounted() {
    if (this.job !== undefined) {
      this.formData.job = this.job.id;

      if (this.job.paramsSchema) {
        this.schema = this.job.paramsSchema;
      }
    }
  },

  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true
      });
    },

    triggerJob() {
      if (this.formData.id !== undefined && this.formData.job !== undefined) {
        SUBVODIO.triggerJob(this.formData).then(() => {
          this.actionResult = {
            error: false,
            message:
              "Job am " +
              new Date().toLocaleString(undefined, settings.dateFormat) +
              " gespeichert."
          };
          this.makeToast();
          this.$emit("update");
          this.$bvModal.hide("start-job-modal");
        });
      } else {
        this.actionResult = {
          error: true,
          message:
            "Es gab einen Fehler bei der Bearbeitung: Job muss ausgewählt werden."
        };
        this.makeToast();
      }
    }
  }
};
</script>

<style scoped>
button {
  margin: 1.4rem 0;
}

.card-body {
  padding: 0;
}

.form-container {
  padding: 1.4rem;
}

.card-header {
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
}

.card {
  margin: 2rem 0;
}
</style>
