<template>
  <div class="registered-items-list">
    <b-tabs content-class="mt-3">
      <b-tab title="Jobs" active>
        <Table :items="jobs" :header="headerJob" :showPagination="true">
          <template v-slot:cell(link)="data">
            <div class="icon-container">
              <img
                src="/images/play.svg"
                alt="play"
                v-b-modal.start-job-modal
                @click="jobToStart = data.item"
              />
              <img
                src="/images/edit.svg"
                alt="edit"
                v-b-modal.register-job-modal
                @click="jobToRegister = data.item"
              />
              <b-form-checkbox
                switch
                v-model="data.item.enabled"
              ></b-form-checkbox>
            </div>
          </template>
        </Table>
        <b-button
          v-b-modal.register-job-modal
          @click="jobToRegister = undefined"
          >Job registrieren</b-button
        >
      </b-tab>
      <b-tab title="Queues">
        <Table :items="queues" :header="headerQueue" :showPagination="false">
          <template v-slot:cell(link)="data">
            <div class="icon-container">
              <img
                src="/images/edit.svg"
                alt="edit"
                v-b-modal.register-queue-modal
                @click="queueToRegister = data.item"
              />
            </div>
          </template>
        </Table>
        <b-button
          v-b-modal.register-queue-modal
          @click="queueToRegister = undefined"
          >Queue registrieren</b-button
        >
      </b-tab>
    </b-tabs>

    <b-modal id="start-job-modal" hide-footer centered title="Job starten">
      <JobStartForm
        :job="jobToStart"
        :jobOptions="jobOptions"
        :queueOptions="queueOptions"
        @update="$emit('update')"
      />
    </b-modal>

    <b-modal
      id="register-queue-modal"
      hide-footer
      centered
      title="Queue registrieren"
    >
      <QueueRegisterForm @update="getQueueList" :queue="queueToRegister" />
    </b-modal>
    <b-modal
      id="register-job-modal"
      hide-footer
      centered
      title="Job registrieren"
    >
      <JobRegisterForm @update="getJobList" :job="jobToRegister" />
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Table from "@/components/Base/Table.vue";
import JobStartForm from "@/components/JobStartForm.vue";
import QueueRegisterForm from "@/components/QueueRegisterForm.vue";
import JobRegisterForm from "@/components/JobRegisterForm.vue";
import SUBVODIO from "@/utils/AdminApi";
import settings from "@/utils/Settings";

export default {
  name: "RegisteredItemsList",
  components: {
    Table,
    JobStartForm,
    QueueRegisterForm,
    JobRegisterForm
  },

  data() {
    return {
      headerJob: [
        {
          key: "id",
          label: "Job-ID",
          sortable: true
        },
        {
          key: "startUrl",
          label: "Start-URL",
          sortable: true
        },
        {
          key: "statusUrl",
          label: "Status-URL",
          sortable: true
        },
        { key: "link", label: "Aktionen", sortable: false }
      ],
      headerQueue: [
        {
          key: "id",
          label: "Queue",
          sortable: true
        },
        {
          key: "type",
          label: "Typ",
          sortable: true
        },
        { key: "link", label: "Aktionen", sortable: false }
      ],
      jobToStart: {},
      queueToRegister: {},
      jobToRegister: {},
      jobOptions: [],
      queueOptions: [],
      queues: [],
      jobs: []
    };
  },
  computed: {
    // TODO: replace the computed properties with data received from BE
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "Eintrag nicht bearbeitet"
        : "Eintrag erfolgreich bearbeitet";
    }
  },

  mounted() {
    this.getJobList();
    this.getQueueList();
  },
  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true
      });
    },
    closeModal() {
      this.$bvModal.hide("confirm-modal");
    },

    setSuccessMessage(type) {
      this.actionResult = {
        error: false,
        message:
          type +
          " am " +
          new Date().toLocaleString(undefined, settings.dateFormat) +
          " geladen."
      };
      // this.makeToast();
    },
    setErrorMessage(error) {
      this.actionResult = {
        error: true,
        message: "Es gab einen Fehler bei der Bearbeitung: " + error.message
      };
      this.makeToast();
    },

    getJobList() {
      SUBVODIO.getJobsAvailable()
        .then(response => {
          this.jobs = response.data;
          this.jobOptions = response.data.map(entry => entry.id);
          this.setSuccessMessage("Queue");
        })
        .catch(error => this.setErrorMessage(error));
    },
    getQueueList() {
      SUBVODIO.getQueuesAvailable()
        .then(response => {
          this.queues = response.data;
          this.queueOptions = response.data.map(entry => entry.id);
          this.setSuccessMessage("Queue");
        })
        .catch(error => this.setErrorMessage(error));
    }
  }
};
</script>

<style scoped>
h3 {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.table-container {
  padding: 1.2rem;
}

.icon-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 0.2rem;
}
</style>
