<template>
  <div class="queue-register-form">
    <div class="form-container">
      <b-form-group label="Queue-ID">
        <b-form-input
          type="text"
          v-model="formData.id"
          :disabled="exisitingQueue"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Typ">
        <b-form-select
          :options="options"
          v-model="formData.type"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="Host">
        <b-form-input v-model="formData.queueDetails.host"></b-form-input>
      </b-form-group>
      <b-form-group label="Port">
        <b-form-input v-model="formData.queueDetails.port"></b-form-input>
      </b-form-group>
      <b-form-group label="Username">
        <b-form-input v-model="formData.queueDetails.username"></b-form-input>
      </b-form-group>
      <b-form-group label="Passwort">
        <b-form-input v-model="formData.queueDetails.passwort"></b-form-input>
      </b-form-group>
      <b-button class="float-right" @click="registerQueue">Speichern</b-button>
    </div>
  </div>
</template>

<script>
import SUBVODIO from "@/utils/AdminApi";
import settings from "@/utils/Settings";

export default {
  name: "QueueRegisterForm",
  components: {},
  props: {
    queue: Object,
  },

  data() {
    return {
      actionResult: {
        error: false,
        message: "",
      },
      formData: { queueDetails: {}, type: "mongo" },
      options: [{ value: "mongo", text: "MongoDB" }],
      exisitingQueue: false,
    };
  },

  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "Queue nicht gespeichert"
        : "Queue erfolgreich gespeichert";
    },
  },

  mounted() {
    if (this.queue) {
      this.formData = this.queue;
      if (this.queue.id) {
        this.exisitingQueue = true;
      }
    }
  },

  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },

    registerQueue() {
      SUBVODIO.registerQueue(this.formData)
        .then(() => {
          this.actionResult = {
            error: false,
            message:
              "Queue am " +
              new Date().toLocaleString(undefined, settings.dateFormat) +
              " gespeichert.",
          };
          this.makeToast();
          this.$emit("update");
          this.$bvModal.hide("register-queue-modal");
        })
        .catch((error) => {
          this.actionResult = {
            error: true,
            message:
              "Es gab einen Fehler bei der Bearbeitung: " + error.message,
          };
          this.makeToast();
        });
    },
  },
};
</script>

<style scoped>
button {
  margin: 1.4rem 0;
}

.card-body {
  padding: 0;
}

.form-container {
  padding: 1.4rem;
}

.card-header {
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
}

.card {
  margin: 2rem 0;
}
</style>
