<template>
  <div class="user-table">
    <Table
      :items="data"
      :header="header"
      :showPagination="false"
      :noOfRows="20"
    >
      <template v-slot:cell(rawDataCount)="data">
        <div class="column">
          <div>Filme: {{ data.item.rawDataCount.movies }}</div>
          <div class="">Serien: {{ data.item.rawDataCount.tvSeries }}</div>
          <div class="underline">____________</div>
          <div>Gesamt: {{ data.item.rawDataCount.total }}</div>
        </div>
      </template>
      <template v-slot:cell(optimizedDataCount)="data">
        <div class="column">
          <div>Filme: {{ data.item.optimizedDataCount.movies }}</div>
          <div class="">
            Serien: {{ data.item.optimizedDataCount.tvSeries }}
          </div>
          <div class="underline">____________</div>
          <div>Gesamt: {{ data.item.optimizedDataCount.total }}</div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import Table from "@/components/Base/Table.vue";

export default {
  name: "ContentCountTable",

  components: {
    Table,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      header: [
        {
          key: "source",
          label: "Quelle",
          sortable: false,
        },
        {
          key: "rawDataCount",
          label: "Rohdaten",
          sortable: false,
        },
        {
          key: "optimizedDataCount",
          label: "Optimierte Daten",
          sortable: false,
        },
      ],
    };
  },
  computed: {},
};
</script>

<style scoped>
.column {
  min-width: 160px;
}

.underline {
  margin-top: -8px;
}
</style>
