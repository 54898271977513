<template>
  <div class="product-bundles">
    <h3>Product Bundles</h3>
    <b-button v-b-modal.add-bundle-modal class="my-3"
      >Product Bundle erstellen</b-button
    >
    <Table :items="items" :header="header">
      <template v-slot:cell(link)="data">
        <img
          src="/images/edit.svg"
          alt="edit"
          v-b-modal.add-bundle-modal
          @click="formData = data.item"
        />
      </template>
    </Table>
    <b-modal id="add-bundle-modal" title="Product Bundle erstellen">
      <b-form-group label="Bundle-Id">
        <b-form-input v-model="formData.id"></b-form-input>
      </b-form-group>
      <b-form-group label="Beschreibung">
        <b-form-textarea v-model="formData.description"></b-form-textarea>
      </b-form-group>
      <b-button
        class="my-3"
        @click="formData.channels.push({ id: counter + 1 })"
        >Channel hinzufügen</b-button
      >
      <div v-for="entry in formData.channels" :key="entry.id">
        <b-form-group label="Channel auswählen">
          <b-form-select
            v-model="entry.name"
            :options="[
              'Data Import MeinVOD',
              'Data Import Netflix',
              'Data Import Arte',
            ]"
          ></b-form-select>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Table from "@/components/Base/Table.vue";
import SUBVODIO from "@/utils/AdminApi";

export default {
  name: "ProductBundles",
  components: { Table },
  data() {
    return {
      user: SUBVODIO.user(),
      formData: {
        id: "",
        description: "",
        channels: [],
      },
      items: [
        {
          id: "Product Bundle Sm",
          description: "Das Bundle Sm beinhaltet die Channel Arte und MeinVOD ",
          channels: [
            { id: 1, name: "Data Import Arte" },
            { id: 2, name: "Data Import MeinVOD" },
          ],
        },

        {
          id: "Product Bundle M",
          description:
            "Das Bundle Sm beinhaltet die Channel Arte, Netlix und MeinVOD ",
          channels: [
            { id: 1, name: "Data Import Arte" },
            { id: 2, name: "Data Import MeinVOD" },
            { id: 3, name: "Data Import Netflix" },
          ],
        },
      ],
      header: [
        {
          key: "id",
          label: "Bundle-ID",
          sortable: true,
        },
        {
          key: "description",
          label: "Beschreibung",
          sortable: true,
        },
        { key: "link", label: "Aktionen", sortable: false },
      ],
    };
  },
  computed: {
    counter() {
      return this.formData.channels.length;
    },
  },
  mounted() {
    this.getJobList();
  },
  methods: {
    setSuccessMessage(type) {
      this.actionResult = {
        error: false,
        message:
          type +
          " am " +
          new Date().toLocaleString(undefined, settings.dateFormat) +
          " geladen.",
      };
      this.makeToast();
    },
    setErrorMessage(error) {
      this.actionResult = {
        error: true,
        message: "Es gab einen Fehler bei der Bearbeitung: " + error.message,
      };
      this.makeToast();
    },
    getJobList() {
      SUBVODIO.getJobsAvailable()
        .then((response) => {
          this.jobs = response.data;
          this.jobOptions = response.data.map((entry) => entry.job);
          this.setSuccessMessage("Queue");
        })
        .catch((error) => this.setErrorMessage(error));
    },
  },
};
</script>
