<template>
  <div class="registered-items-list">
    <b-tabs content-class="my-3">
      <Table :items="items" :header="headerMs" :showPagination="false">
        <template v-slot:cell(logo)="data">
          <img :src="'data:image/png;base64,' + data.item.logo" alt="logo" />
        </template>
        <template v-slot:cell(active)="data">
          <b-form-checkbox
            v-model="data.item.active"
            switch
            @change="toggleSource(data.item, $event)"
          />
        </template>
        <template v-slot:cell(link)="data">
          <div>
          <img
            src="/images/edit.svg"
            alt="edit"
            v-b-modal.register-ms-modal
            @click="sourceToEdit = data.item"
            class="mr-3"
          />
          </div>
          <div>
          <img v-if="data.item.metaData.gatherJob"
            src="/images/refresh.svg"
            alt="gather"
            title="Rohdaten aktualisieren"
            class="btn"
            @click="performSourceJob(data.item.metaData.gatherJob)"
          />
                    <img v-if="data.item.metaData.optimizeJob"
            src="/images/icon.svg"
            alt="optimize"
            title="Daten Optimieren"
            width="20px"
            class="btn"
            style="border: none; padding: 0"
            @click="performSourceJob(data.item.metaData.optimizeJob)"
          />
                    <img v-if="data.item.metaData.finalizeJob"
            src="/images/play.svg"
            alt="finalize"
            title="Daten finalisieren"
            class="btn"
            @click="performSourceJob(data.item.metaData.finalizeJob)"
          />
          </div>
        </template>
      </Table>
      <b-button
        v-b-modal.register-ms-modal
        @click="sourceToEdit = undefined"
        class="my-3"
        >Quelle anlegen</b-button
      >
    </b-tabs>

    <b-modal id="register-ms-modal" hide-footer centered title="Quelle anlegen">
      <SourceRegisterForm :source="sourceToEdit" />
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Table from "@/components/Base/Table.vue";
import SourceRegisterForm from "@/components/SourceRegisterForm.vue";
import SUBVODIO from "@/utils/AdminApi";
import settings from "@/utils/Settings";

export default {
  name: "SourcesList",
  components: {
    Table,
    SourceRegisterForm,
  },
  props: {
    entries: {
      type: Array,
      default: () => {
        return [
          {
            id: "123-3454-55635654-5636",
            name: "Scheduler",
            baseUrl: "Scheduler.com",
            component: "scheduler",
          },
          {
            id: "123-3454-55635654-5636",
            name: "Data Importer",
            baseUrl: "data.com",
            component: "importer",
          },
          {
            id: "123-3454-55635654-5636",
            name: "User management",
            baseUrl: "users.com",
            component: "userMngmt",
          },
        ];
      },
    },
  },
  data() {
    return {
      headerMs: [
        {
          key: "logo",
          label: "Logo",
        },
        {
          key: "id",
          label: "Id",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "homepage",
          label: "Homepage",
          sortable: true,
        },
        {
          key: "active",
          label: "Aktiv?",
        },
        { key: "link", label: "Aktionen", sortable: false },
      ],
      headerQueue: [
        {
          key: "id",
          label: "Queue",
          sortable: true,
        },
        {
          key: "type",
          label: "Typ",
          sortable: true,
        },
      ],
      sourceToEdit: {},
      items: [],
    };
  },
  computed: {
    // TODO: replace the computed properties with data received from BE

    componentToEdit() {
      return this.sourceToEdit ? this.sourceToEdit.component : "";
    },

    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? "Eintrag nicht bearbeitet"
        : "Eintrag erfolgreich bearbeitet";
    },
  },

  mounted() {
    this.getMsList();
  },
  methods: {
    performSourceJob(job) {
      if (Array.isArray(job)) {
        job.forEach(j => SUBVODIO.triggerJob(j));
      }
      else {
        SUBVODIO.triggerJob(job);
      }
    },
    toggleSource(source, state) {
      if (state) {
        SUBVODIO.activateSource(source.id);
      } else {
        SUBVODIO.deactivateSource(source.id);
      }
    },
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },
    closeModal() {
      this.$bvModal.hide("confirm-modal");
    },

    setSuccessMessage(type) {
      this.actionResult = {
        error: false,
        message:
          type +
          " am " +
          new Date().toLocaleString(undefined, settings.dateFormat) +
          " geladen.",
      };
      this.makeToast();
    },
    setErrorMessage(error) {
      this.actionResult = {
        error: true,
        message: "Es gab einen Fehler bei der Bearbeitung: " + error.message,
      };
      this.makeToast();
    },

    getMsList() {
      SUBVODIO.getSources()
        .then((response) => {
          this.items = response.data.map(source => {
            source.metaData = JSON.parse(source.metaData || "{}");
            return source;
          });
          this.setSuccessMessage("Quellen");
        })
        .catch((error) => this.setErrorMessage(error));
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.table-container {
  padding: 1.2rem;
}

.icon-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 0.2rem;
}
</style>
